import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Avatar, { AvatarProps } from "@mui/material/Avatar";
import { getDataAsync } from "../../services/getData";
import { useQuery } from "react-query";
import { LinearProgress } from "@mui/material";

type rowProps = {
  name: string;
  image: string;
};

interface Column {
  id: "id" | "status" | "gender" | "name" | "species" | "image";
  label: string;
  width?: number;
  align?: "left" | "right" | "center" | undefined;
  Cell?: (row: rowProps) => AvatarProps;
}
const columns: readonly Column[] = [
  { id: "id", label: "ID", width: 50, align: "center" },
  {
    id: "image",
    label: "Image",
    width: 56,
    align: "center",
    Cell: (row) => (
      <Avatar alt={row.name} src={row.image} sx={{ width: 56, height: 56 }} />
    ),
  },
  { id: "name", label: "Name", align: "left" },
  { id: "status", label: "Status", align: "left" },
  { id: "species", label: "Species", align: "left" },
  { id: "gender", label: "Gender", align: "center" },
];
const rowsPerPage = 20;
const URL = "https://rickandmortyapi.com/api/character";

const TableInfo = ({ status, name }: any) => {
  const [url, setUrl] = useState(URL);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const { isLoading, data } = useQuery(
    ["characters", url],
    () => getDataAsync(url),
    { keepPreviousData: true }
  );

  useEffect(() => {
    if (data) {
      setRows(data.results);
    }
  }, [data]);

  useEffect(() => {
    setUrl(`${URL}?page=${page + 1}&name=${name}&status=${status}`);
  }, [page, status, name]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: "50vh" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ width: column.width }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={6} padding="none">
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
            {rows &&
              rows.length > 0 &&
              rows.map((row: any) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row["id"]}>
                    {columns.map((column) => {
                      const cell = column.Cell ? column.Cell(row) : null;
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {cell ? cell : row[column.id]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            {!rows && (
              <TableRow>
                <TableCell
                  colSpan={6}
                  padding="none"
                  sx={{ textAlign: "center", color: "red" }}
                >
                  <p>No results</p>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[rowsPerPage]}
        component="div"
        count={data?.info?.count || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </Paper>
  );
};

export default TableInfo;
