import React, { useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import "./App.scss";
import Filter from "./components/Filter/Filter";
import TableInfo from "./components/TableInfo/TableInfo";

const queryClient = new QueryClient();

const App = () => {
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <div className="header">Adiona</div>
        <div className="content">
          <div className="menu">Menu</div>
          <div className="table-filter">
            <Filter
              setStatus={setStatus}
              status={status}
              name={name}
              setName={setName}
            />
            <TableInfo name={name} status={status} />
          </div>
        </div>
      </div>
    </QueryClientProvider>
  );
};

export default App;
