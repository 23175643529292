import React, { ChangeEvent } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { STATUS_OPTIONS } from "../../constants/constants";

import "./Filter.scss";

const Filter = ({ status, setStatus, name, setName }: any) => {
  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  return (
    <section className="filter">
      <h2>Search By:</h2>
      <Box
        sx={{
          minWidth: 120,
          display: "flex",
          flexDirection: "row",
          marginBottom: 3,
          backgroundColor: "white",
        }}
      >
        <FormControl fullWidth sx={{ marginRight: 2 }}>
          <InputLabel id="select-label">Status</InputLabel>
          <Select value={status} label="Status" onChange={handleChange}>
            {STATUS_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label="Name"
            variant="outlined"
            value={name}
            onChange={handleInputChange}
          />
        </FormControl>
      </Box>
    </section>
  );
};

export default Filter;
